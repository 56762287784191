
import { useEffect } from "react";


const useImportScript = (resourceUrl, callback) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = resourceUrl;
        script.defer = true;
        document.body.appendChild(script);
        script.onload = () => callback();
        return () => {
            document.body.removeChild(script);
        }
    },[resourceUrl]);
    
}

export default useImportScript;