import React from "react"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import useImportScripts from "../useImportScripts";
import "../../styles/cndk.beforeafter.css"
//import $ from "jquery"




const ProcessSectionImgOnly = (props) => {


    useImportScripts([
        "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js",
        "/js/cndk.beforeafter.js",
        "/js/cndk.config.js"
    ]);

    /*useImportScript("https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js",() => {

        console.log("1: jquery loaded");
    })

    useImportScript("/js/cndk.beforeafter.js",() => {

        console.log("2: cndk loaded");

    })

    useImportScript("/js/cndk.config.js",() => {

        console.log("3: cndk config loaded");
 
    })*/

    console.log(props.webNew);
    return (        
        <section id="case-study-process" className="case-study-process-mac">
            <h3>Naše výsledné spracovanie<StaticImage className="img" src="../../images/new-web/arrow.svg" alt="Šípka vpravo" placeholder="blurred" /></h3>
            <div className="comparison2">
                <GatsbyImage  className="mockup-notebook notebook-image"  image={getImage(props.webNew)} alt="Mac"/>
            </div>
        </section>
    )
}

export default ProcessSectionImgOnly;