import React from "react"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import challengeIcon from "../../images/new-web/challenge.svg";
import processIcon from "../../images/new-web/processing.svg";
import notepadIcon from "../../images/new-web/notepad.svg";
import arrow from "../../images/new-web/arrow.svg";

const InfoSection = (props) => {


    const sluzbyArray = props.caseStudySluzby.split(";");
    var sluzby = <></>;
    sluzbyArray.map((sluzba) => {
        sluzby = <>{sluzby}<li><span>+</span> {sluzba}</li></>;
    });

    return(
        <section id="case-study-info">
            <div class="heading">
                <h2>Čo sa nám podarilo</h2>
            </div>
            <div class="case-desc">
                <div class="logo">
                    <img className="img" loading="lazy" src={props.logo} alt={props.client + " logo"} />
                </div>
                <div class="basic-info">
                    <div>
                        <h3>Rok</h3>
                        <p>{props.caseStudyRok}</p>
                        <h3>Výsledok našej práce</h3>
                        <a href={props.realUrl} target="_blank">www.{ props.realUrl.substr(8,props.realUrl.length - 9) }</a>
                    </div>
                    <div>
                        <h3>Realizované<img className="img" src={arrow} alt="Šípka vpravo" loading="lazy" /></h3>
                        <ul>
                            {sluzby}
                        </ul>
                    </div>
                </div>
                <div class="vyzva">
                    <div>
                        <img className="img" src={challengeIcon} alt="Výzva" loading="lazy" />
                    </div>
                    <div>
                        <h3>Naša výzva<img className="img" src={arrow} alt="Šípka vpravo" loading="lazy" /></h3>
                        <p>{props.caseStudyVyzva}</p>
                    </div>
                </div>
                <div class="vyzva">
                    <div>
                        <img className="img" src={processIcon} alt="Proces" loading="lazy" />
                    </div>
                    <div>
                        <h3>Ako sme postupovali?<img className="img" src={arrow} alt="Šípka vpravo" loading="lazy" /></h3>
                        <p>{props.caseStudyProces}</p>
                    </div>
                </div>
                <div class="riesenie">
                    <div>
                        <img className="img" src={notepadIcon} alt="Zápisník" loading="lazy" />
                    </div>
                    <div>
                        <h3>Ako sme klientovi pomohli naším riešením?<img className="img" src={arrow} alt="Šípka vpravo" loading="lazy" /></h3>
                        <p>{props.caseStudyRiesenie}</p>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default InfoSection;
