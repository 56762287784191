import React from "react";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import challengeIcon from "../../images/new-web/challenge.svg";
import processIcon from "../../images/new-web/processing.svg";
import notepadIcon from "../../images/new-web/notepad.svg";
import arrow from "../../images/new-web/arrow.svg";

const ReferenceSection = (props) => {
  return (
    <section id="case-study-reference">
      <div className="user">
        <img
          className="user-avatar"
          src={"/assets/img/user-icon.svg"}
          width={50}
          height={50}
        />
        <div>
          <p className="user-name">{props.name}</p>
          <p className="company-name">{props.company}</p>
        </div>
      </div>

      <iframe
        frameborder="0"
        src={props.video}
        title="YouTube video"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen="allowfullscreen"
      ></iframe>
    </section>
  );
};

export default ReferenceSection;
