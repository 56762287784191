import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const NextProject = (props) => {
    return(
        <section id="next">
            <div>
                <a href={"/referencia/" + props.url}>
                    Následujúci<StaticImage className="img" src="../../images/new-web/arrow.svg" alt="Šípka vpravo" loading="lazy" placeholder="blurred" /><br /> projekt
                </a>
            </div>
            <div class="detail">
                <img className="img" src={props.logoWhite} alt={props.client} client="clientlogo" />
                <p>
                    {props.description}
                </p>
            </div>
        </section>
    )
}

export default NextProject;
