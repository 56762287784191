import React from "react"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Scrollbar, Autoplay,Navigation } from "swiper";

import "swiper/scss"
import "swiper/scss/pagination"
import "swiper/scss/scrollbar"
import "swiper/scss/navigation"

const SliderSectionProject = (props) => {
    return (
        <section id="img-slider">
            <Swiper
                modules={[Pagination, Scrollbar, Autoplay, Navigation]}
                loop={true}
                direction={'horizontal'}
                pagination={{clickable: true}}
                navigation={{}}
                autoplay={{ delay: 15000}}
            >
                <SwiperSlide>
                    <GatsbyImage className="img" image={getImage(props.tablet1)} alt="Screen" />
                </SwiperSlide>
                <SwiperSlide>
                    <GatsbyImage className="img" image={getImage(props.tablet2)} alt="Screen" />
                </SwiperSlide>
                <SwiperSlide>
                    <GatsbyImage className="img" image={getImage(props.tablet3)} alt="Screen" />
                </SwiperSlide>
                
                {props.tablet4 && 
                <SwiperSlide>
                <GatsbyImage className="img" image={getImage(props.tablet4)} alt="Screen" />
                </SwiperSlide>
                }
                
                {props.tablet5 &&
                <SwiperSlide>
                <GatsbyImage className="img" image={getImage(props.tablet5)} alt="Screen" />
                </SwiperSlide>
                }
                
            </Swiper>
        </section>
    )
}

export default SliderSectionProject;