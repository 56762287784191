import React from "react"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"


const MainSectionProjekt = (props) => {

    return (
        <section className="section-dark">
            <div className="banner">
                <div className="banner-img">
                    <GatsbyImage className="img" image={getImage(props.caseStudyMobile)} alt={props.client} />
                </div>
                <h1>
                    {props.client}
                </h1>
                <p>{props.caseStudyTitle}</p>
                <StaticImage className="arrow-down img" placeholder="blurred" src="../../images/new-web/arrow.svg" alt="Arrow" />
            </div>
        </section>
    )

}

export default MainSectionProjekt