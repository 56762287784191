import React from "react";
import { graphql } from "gatsby";

//above-the-fold
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import MainSectionProjekt from "../../components/projekt/mainSection.js";
import MainSectionProjektTablet from "../../components/projekt/mainSectionTablet.js";

import "../../styles/pripadova-studia.scss";
import SliderSectionProject from "../../components/projekt/sliderSection";
import InfoSection from "../../components/projekt/infoSection";
import ReferenceSection from "../../components/projekt/ReferenceSection";
import ProcessSection from "../../components/projekt/processSection";
import ProcessSectionImgOnly from "../../components/projekt/processSectionImgOnly";
import ContactUs from "../../components/contactUs";
import NextProject from "../../components/projekt/nextProject";

//

export const pageQuery = graphql`
  query($url: String) {
    mysqlProjects(url: { eq: $url }) {
      url
      real_url
      client
      hashtag
      project_description
      case_study_title
      case_study_rok
      case_study_sluzby
      case_study_vyzva
      case_study_proces
      case_study_riesenie
      ogDescription
      newTemplate
    }
    files: allFile(
      filter: {
        relativeDirectory: { eq: $url }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        publicURL
        childImageSharp {
          gatsbyImageData(quality: 100, webpOptions: { quality: 100 })
        }
      }
    }
    projekty: allMysqlProjects(sort: { fields: case_study_rok, order: DESC }) {
      nodes {
        url
        client
        case_study_description
      }
    }
    logosWhite: allFile(
      filter: {
        name: { eq: "logo-white" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        relativeDirectory
        publicURL
      }
    }
  }
`;

const ProjectPage = (pageQuery) => {
  const {
    client,
    case_study_title,
    url,
    case_study_rok,
    real_url,
    case_study_sluzby,
    case_study_vyzva,
    case_study_proces,
    case_study_riesenie,
    ogDescription,
    newTemplate,
  } = pageQuery.data.mysqlProjects;

  //premapovanie obrázkov
  const images = {};
  pageQuery.data.files.nodes.map((node) => {
    let index = node.name;
    images[index] = node;
  });

  var nextProject;
  pageQuery.data.projekty.nodes.map((node, i) => {
    if (node.url == url) {
      nextProject =
        i == pageQuery.data.projekty.nodes.length - 1
          ? pageQuery.data.projekty.nodes[0]
          : pageQuery.data.projekty.nodes[i + 1];
    }
  });

  const logos = {};
  pageQuery.data.logosWhite.nodes.map((node) => {
    let index = node.relativeDirectory;
    logos[index] = node.publicURL;
  });

  return (
    <Layout pageName="projekt-main">
      <SEO title={"Referencia " + client} description={ogDescription} />

      {/* client === 'Plusim' || client === 'Pestucci' || client === 'Ecomed' */ newTemplate &&
      typeof window == "object" ? (
        <MainSectionProjektTablet
          client={client}
          caseStudyTitle={case_study_title}
          caseStudyMobile={images["case-study-mobile@2x"]}
          inlineStyle={
            client === "Pestucci" &&
            window.innerWidth > 675 && { ...{ left: "10%", top: -100 } }
          }
        />
      ) : (
        <MainSectionProjekt
          client={client}
          caseStudyTitle={case_study_title}
          caseStudyMobile={images["case-study-mobile@2x"]}
        />
      )}
      <SliderSectionProject
        tablet1={images["tablet_" + url + "1"]}
        tablet2={images["tablet_" + url + "2"]}
        tablet3={images["tablet_" + url + "3"]}
        tablet4={images["tablet_" + url + "4"]}
        tablet5={images["tablet_" + url + "5"]}
      />
      <InfoSection
        caseStudyRok={case_study_rok}
        client={client}
        logo={images["logo"].publicURL}
        realUrl={real_url}
        caseStudySluzby={case_study_sluzby}
        caseStudyVyzva={case_study_vyzva}
        caseStudyProces={case_study_proces}
        caseStudyRiesenie={case_study_riesenie}
      />

      {/* !!! Zatiaľ sú videoreferencie statické (iba jedna referencia ihlove) pri nových referenciách sa dáta budú ťahať z databázy !!! */}
      {client == "Ihlove" ? (
        <ReferenceSection
          name="Katarína Kobušová"
          company="IHLOVE"
          video="https://www.youtube-nocookie.com/embed/s5QfFaMfkmc"
        />
      ) : null}

      {/* client === 'Plusim' || client === 'Pestucci' || client === 'Ecomed' */ newTemplate ? (
        <ProcessSectionImgOnly webNew={images["web-new"]} />
      ) : (
        <ProcessSection
          webNew={images["web-new"]}
          webOld={images["web-old"]}
          url={url}
        />
      )}

      <ContactUs
        text={<>Máte záujem o podobný projekt?</>}
        button="Prekonzultujte to s nami zadarmo"
      />
      <NextProject
        url={nextProject.url}
        client={nextProject.client}
        description={nextProject.case_study_description}
        logoWhite={logos[nextProject.url]}
      />
    </Layout>
  );
};

export default ProjectPage;
