import React from "react";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import useImportScript from "../useImportScript";
import useImportScripts from "../useImportScripts";
import "../../styles/cndk.beforeafter.css";
//import $ from "jquery"

const ProcessSection = (props) => {
  useImportScripts([
    "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js",
    "/js/cndk.beforeafter.js",
    "/js/cndk.config.js",
  ]);

  /*useImportScript("https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js",() => {

        console.log("1: jquery loaded");
    })

    useImportScript("/js/cndk.beforeafter.js",() => {

        console.log("2: cndk loaded");

    })

    useImportScript("/js/cndk.config.js",() => {

        console.log("3: cndk config loaded");

    })*/

  return (
    <section
      id="case-study-process"
      className={
        props.url == "kumat" || props.url == "louloudi" ? "separator" : ""
      }
    >
      <h3>
        Naše výsledné spracovanie
        <StaticImage
          className="img"
          src="../../images/new-web/arrow.svg"
          alt="Šípka vpravo"
          placeholder="blurred"
        />
      </h3>
      <div className="comparison">
        <div className="slider">
          <div data-type="data-type-image">
            <div data-type="before">
              <GatsbyImage className="img" image={getImage(props.webNew)} />
              {/*<img className="img" src={props.webNew.publicURL} />*/}
            </div>

            <div data-type="after">
              <GatsbyImage className="img" image={getImage(props.webOld)} />
              {/*<img className="img" src={props.webOld.publicURL} />*/}
            </div>
          </div>
        </div>
        <StaticImage
          src="../../images-projects/mockup.png"
          alt="Mac"
          className="mockup"
          placeholder="blurred"
        />
      </div>
    </section>
  );
};

export default ProcessSection;
